import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './styles/App.scss';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import SupportPage from './pages/SupportPage';
import ContactPage from './pages/ContactPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import EarlyAccessPage from './pages/EarlyAccess';
import GeneralNavbar from './components/Navbar';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/support",
    element: <SupportPage />
  },
  {
    path: "/contact",
    element: <ContactPage />
  },
  {
    path: "/privacy",
    element: <PrivacyPolicyPage />
  },
  {
    path: "/earlyaccess",
    element: <EarlyAccessPage />
  },
]);

/*const earlyaccessRouter = createBrowserRouter([
  {
    path: "/",
    element: <EarlyAccessPage />
  }
]);*/

function App() {
  return (
    <div className="App">
      <GeneralNavbar />

      <div className='page'>
        <RouterProvider router={router} />
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
