import { Accordion } from "react-bootstrap";

function SupportPage() {
    return (
        <div style={{ "margin": "20px" }}>
            <h1>Death Dash Support</h1>
            <h5 className="default-font">Here is the place where your questions can be answered.</h5>

            <Accordion data-bs-theme="dark">
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="default-font copyright-font">What languages does Death Dash support?</Accordion.Header>
                    <Accordion.Body className="default-font">
                        Currently, Death Dash is available only in English, but I'm looking forward to make it available to as many languages as possible.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="default-font copyright-font">What are the supported platforms?</Accordion.Header>
                    <Accordion.Body className="default-font">
                        Death Dash is designed to be mobile game and currently its available only for android.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header className="default-font copyright-font">Will Death Dash support more platforms?</Accordion.Header>
                    <Accordion.Body className="default-font">
                        As said in previous answer, Death Dash is designed for mobile devies and the only platform I'm looking forward to add is iOS.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>
    )
}

export default SupportPage;
