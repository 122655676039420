
function PrivacyPolicyPage() {
    return (
        <div style={{ "margin": "20px" }}>
            <h1 className="title">Death Dash Privacy Policy</h1>

            <p className="copyright-font"><strong className="copyright-font">Last updated:</strong> 2 September, 2024</p>

            <p className="copyright-font">Thank you for choosing to play <strong className="copyright-font">Death Dash</strong> (referred to as "the Game"). This Privacy Policy outlines how we collect, use, and protect your personal information when you use the Game. We are committed to safeguarding your privacy and ensuring a safe gaming experience for all users, including children.</p>

            <p className="copyright-font">By playing Death Dash, you agree to the collection and use of information in accordance with this Privacy Policy.</p>

            <h4 className="copyright-font">1. Information We Collect</h4>
            <p className="copyright-font">At present, <strong className="copyright-font">Death Dash</strong> does not collect any personal information. The game is designed as a simple, offline experience, and no personal data or device data is stored or shared.</p>

            <ul>
                <li className="copyright-font"><strong className="copyright-font">Personal Information:</strong> We do not collect any personally identifiable information (PII), such as your name, email address, or contact details.</li>
                <li className="copyright-font"><strong className="copyright-font">Usage Data:</strong> The Game does not collect data on your gameplay, scores, or in-game behavior.</li>
                <li className="copyright-font"><strong className="copyright-font">Device Information:</strong> No device-specific information, such as your IP address, device model, or operating system, is collected.</li>
            </ul>

            <h4 className="copyright-font">2. Google Play Services</h4>
            <p className="copyright-font">Since the Game is available on Google Play, it may use Google Play Services. Google Play may collect certain data for the purpose of improving its service and user experience. This includes data such as:</p>

            <ul>
                <li className="copyright-font">Device information (such as the type of device, operating system, and unique device identifiers).</li>
                <li className="copyright-font">Log data, including your interactions with Google Play and the Game.</li>
            </ul>

            <p className="copyright-font">For more details on how Google collects and handles your data, please review <a className="copyright-font" href="https://policies.google.com/privacy">Google's Privacy Policy</a>.</p>

            <h4 className="copyright-font">3. Game for All Ages</h4>
            <p className="copyright-font"><strong className="copyright-font">Death Dash</strong> is intended for players of all ages, including children. We comply with child privacy protection laws, including the Children's Online Privacy Protection Act (COPPA), to ensure that no personal information from children under the age of 13 is knowingly collected.</p>

            <p className="copyright-font">Since no personal or identifiable information is collected, there is no risk of a child's personal data being shared or exposed.</p>

            <h4 className="copyright-font">4. Third-Party Services</h4>
            <p className="copyright-font"><strong className="copyright-font">Death Dash</strong> does not integrate with any third-party services, such as advertising networks, social media platforms, or analytics tools. We do not share, sell, or disclose any information to third parties.</p>

            <h4 className="copyright-font">5. Children's Privacy</h4>
            <p className="copyright-font">We understand the importance of protecting children's privacy, especially online. Since <strong className="copyright-font">Death Dash</strong> does not collect any personal information, there is no need for parents or guardians to worry about data collection from children under 13.</p>

            <p className="copyright-font">If, at any point in the future, the Game introduces new features requiring data collection, we will ensure that these comply with applicable child protection regulations, including obtaining parental consent when necessary.</p>

            <h4 className="copyright-font">6. Data Security</h4>
            <p className="copyright-font">Since we do not collect any personal or gameplay data, we do not store any data related to the Game. However, we encourage users of all ages to secure their devices by using strong passwords and keeping them up to date to prevent unauthorized access.</p>

            <h4 className="copyright-font">7. Changes to This Privacy Policy</h4>
            <p className="copyright-font">We may update this Privacy Policy from time to time to reflect new features or changes in data handling. Any updates will be posted on this page, and the "Last updated" date will be modified accordingly. We encourage you to review this policy periodically to stay informed.</p>

            <h4 className="copyright-font">8. Contact Us</h4>
            <p className="copyright-font">If you have any questions or concerns about this Privacy Policy or the practices of <strong className="copyright-font">Death Dash</strong>, please contact us at: <a className="copyright-font" href="contact">Contact Page</a></p>

            <p className="copyright-font"><strong className="copyright-font">Note:</strong> If future updates introduce new features like online leaderboards, third-party ads, or analytics, this Privacy Policy will be updated accordingly to provide transparency and comply with data protection laws.</p>

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>
    )
}

export default PrivacyPolicyPage;
